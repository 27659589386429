interface DoPostbackParameters {
  eventTarget: string;
  eventArgument: string;
}

const getDoPostbackParameters = (onClickText: string): DoPostbackParameters => {
  const regexPattern = /.*?__doPostBack\('(.*?)','(.*?)'\).*/;
  const match = onClickText.match(regexPattern);
  return {
    eventTarget: match[1],
    eventArgument: match[2],
  };
};

export { getDoPostbackParameters };
export default getDoPostbackParameters;
