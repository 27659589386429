import $ from "jquery";

export function scrollTo(objectId: string) {
  if ($(objectId).length) {
    $("html, body").animate(
      {
        scrollTop: $(objectId).offset().top,
      },
      500
    );
  }
}

export default scrollTo;
