import { AlgoliaSearchHit } from "../local-types/algoliaitem";

function trendingProductCategory(categories: string[]) {
  if (categories.indexOf("Accessories") !== -1) return "Accessories";
  if (categories.indexOf("Clothing") !== -1) return "Apparel";
  if (categories.indexOf("Balls") !== -1) return "Balls";
  if (categories.indexOf("Bags") !== -1) return "Bags";
  if (categories.indexOf("Shoes") !== -1) return "Shoes";
  return "Other"; 
}

function GetRetailPrice(product: AlgoliaSearchHit): string {
  if (
    typeof product.retailPrice === "number" &&
    product.retailPrice > product.price
  ) {
    return `<span class="retail">$${product.retailPrice.toFixed(2)}</span>`;
  } else {
    return "";
  }
}

export function getTrendingProductString(
  product: AlgoliaSearchHit,
  list: string
): string {
  const containerDiv = document.createElement("div");
  containerDiv.className = "trendprod";
  if (
    typeof product.name !== "string" ||
    typeof product.url !== "string" ||
    typeof product.price !== "number"
  )
    return `<div />`;

  const name = product.name.replace("/", " / ");
  if (typeof product.images !== "undefined") {
    const mainImage = product.images.find((f) => f.type === "MAIN");
    if (typeof mainImage !== "undefined") {
      const cartSizeImage = mainImage.sizes.find((f) => f.size === 30);
      if (typeof cartSizeImage !== "undefined") {
        containerDiv.style.backgroundImage = `url(${cartSizeImage.url})`;
        containerDiv.style.backgroundRepeat = "no-repeat";
        containerDiv.style.backgroundPosition = "10px center";
        containerDiv.style.backgroundSize = "auto";
      }
    }
  }
  let price = `$${product.price.toFixed(2)}`;
  if (typeof product.hasInCartPrice === "boolean" && product.hasInCartPrice)
    price = "see in cart";

  containerDiv.dataset.id = product.id.toString();
  containerDiv.dataset.name = `Trending Product : ${product.name}`;
  containerDiv.dataset.category = trendingProductCategory(product.categories);
  containerDiv.dataset.brand = product.manufacturer;
  containerDiv.dataset.position = "0";
  containerDiv.dataset.list = list;
  containerDiv.dataset.link = product.url;
  containerDiv.innerHTML = `${name}<br />
      <span>${price}</span> ${GetRetailPrice(product)}`;
  const anchor = document.createElement("a");
  anchor.href = product.url;
  anchor.className = "prlnk text-primary";
  anchor.appendChild(containerDiv);
  return anchor.outerHTML;
}
