type DoPostBack = (eventTarget: string, eventArgument: string) => void;

interface MyWindow extends Window {
  __doPostBack: DoPostBack;
}

declare const window: MyWindow;

// eslint-disable-next-line no-underscore-dangle
export default window.__doPostBack;
