import $ from "jquery";

export function overlayOn(inserthtml: string) {
  if (inserthtml.length > 0) {
    $(`<br /><span class="h4">${inserthtml}</span>`).appendTo("#overlaytext");
  }
  document.getElementById("overlay").style.display = "block";
}

export function overlayOff() {
  document.getElementById("overlay").style.display = "none";
}
