/* Open the sidenav */
export function openNav(navName: string) {
  if (navName === "searchNav") {
    const searchInput = document.querySelector(
      "#site-search-mob"
    ) as HTMLInputElement;
    searchInput.focus();
  }
  document.getElementById(navName).classList.add("openNav");
  document.body.classList.add("navOpenNoScroll");
}

/* Close/hide the sidenav */
export function closeNav(navName: string) {
  if (navName === "searchNav") {
    const searchInput = document.querySelector(
      "#site-search-mob"
    ) as HTMLInputElement;
    searchInput.blur();
  }
  document.getElementById(navName).classList.remove("openNav");
  document.body.classList.remove("navOpenNoScroll");
}
