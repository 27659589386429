import $ from "jquery";
import * as validation from "./components/validation";

export function CustomDrilling() {
  $.ajax({
    url: "/api/drillinginserts/finger",
    dataType: "json",
    success: (insertsArray) => {
      $(insertsArray).each((index, item) => {
        $(".fingerInsert_Size").append(
          $("<option />")
            .val(item.size)
            .text(item.size)
            .data("colors", item.colors)
        );
      });
    },
  });
  $.ajax({
    url: "/api/drillinginserts/thumb",
    dataType: "json",
    success: (insertsArray) => {
      $(insertsArray).each((index, item) => {
        $("#thumb-size").append(
          $("<option />")
            .val(item.size)
            .text(item.size)
            .data("colors", item.colors)
        );
      });
    },
  });
  $("#chkFingerInserts").on("change", (e) => {
    if ($(e.currentTarget).prop("checked")) {
      $("#finger-inserts-container").removeClass("d-none");
      validation.addElementValidation("#finger-inserts-container select");
    } else {
      validation.removeElementValidation("#finger-inserts-container select");
      $("#finger-inserts-container").addClass("d-none");
      $("#finger-inserts-container .finger-insert-selector").addClass("d-none");
      $("#finger-inserts-container select").val("");
    }
  });
  $("#chkThumbInsert").on("change", (e) => {
    if ($(e.currentTarget).prop("checked")) {
      $("#thumb-size-container").removeClass("d-none");
      validation.addElementValidation(".thumb-insert-selector select");
    } else {
      validation.removeElementValidation(".thumb-insert-selector select");
      $(".thumb-insert-selector").addClass("d-none");
      $(".thumb-insert-selector select").val("");
    }
  });
  $(".insertSize").on("change", (e) => {
    const colorId = $(e.currentTarget).data("color-id");
    const $colorSelect = $(`#${colorId}`);
    const colors = $(`#${e.currentTarget.id} option:checked`).data("colors");
    $colorSelect.empty();
    $colorSelect.append($("<option />").val("").text("Select Color:"));
    if ($(e.currentTarget).val() !== "") {
      $(colors).each((index, item) => {
        $colorSelect.append($("<option />").val(item.stockId).text(item.color));
      });
      $colorSelect.parent().removeClass("d-none");
      const styleId = $colorSelect.data("style-id");
      if (styleId !== undefined) {
        $(`#${styleId}`).parent().addClass("d-none");
        $(`#${styleId}`).val("");
      }
    } else {
      $colorSelect.parent().addClass("d-none");
      $colorSelect.val("");
      if ($colorSelect.data("style-id") !== undefined) {
        const styleId = $colorSelect.data("style-id");
        $(`#${styleId}`).parent().addClass("d-none");
        $(`#${styleId}`).val("");
      }
    }
  });
  $(".insertColor").on("change", (e) => {
    if ($(e.currentTarget).val() !== "") {
      const styleId = $(e.currentTarget).data("style-id");
      if (styleId !== undefined) {
        $(`#${styleId}`).parent().removeClass("d-none");
      }
    } else {
      const styleId = $(e.currentTarget).data("style-id");
      if (styleId !== undefined) {
        $(`#${styleId}`).parent().addClass("d-none");
        $(`#${styleId}`).val("");
      }
    }
  });
  $("#form1").on("submit", (e) => {
    if (validation.validateAllValid()) {
      const customDrillingOptions: any = {};
      customDrillingOptions.left_style = "";
      customDrillingOptions.left_stockID = "";
      customDrillingOptions.right_style = "";
      customDrillingOptions.right_stockID = "";
      customDrillingOptions.thumb_color = "";
      customDrillingOptions.thumb_stockID = "";
      if ($("#chkFingerInserts").prop("checked")) {
        customDrillingOptions.left_style = $("#left-finger-style").val();
        customDrillingOptions.left_stockID = $("#left-finger-color").val();
        customDrillingOptions.right_style = $("#right-finger-style").val();
        customDrillingOptions.right_stockID = $("#right-finger-color").val();
      }
      if ($("#chkThumbInsert").prop("checked")) {
        customDrillingOptions.thumb_color = $("#thumb-color option:selected")
          .first()
          .text();
        customDrillingOptions.thumb_stockID = $("#thumb-color").val();
      }
      customDrillingOptions.layout = $("#drill-style").val();
      customDrillingOptions.comments = $("#comments").val();
      customDrillingOptions.hand = $("#drill-hand").val();
      customDrillingOptions.balanceHole = $("#drill-balance-hole").val();
      customDrillingOptions.pinPosition = $("#drill-pin-location").val();
      customDrillingOptions.trackType = $("#drill-roll-type").val();
      customDrillingOptions.bridge = $("#bridge").val();
      customDrillingOptions.left_top = $("#left_top").val();
      customDrillingOptions.left_bottom = $("#left_bottom").val();
      customDrillingOptions.left_reverse = $("#left_reverse").val();
      customDrillingOptions.left_forward = $("#left_forward").val();
      customDrillingOptions.left_hole = $("#left_hole").val();
      customDrillingOptions.left_true = $("#left_true").val();
      customDrillingOptions.left_cut = $("#left_cut").val();
      customDrillingOptions.right_top = $("#right_top").val();
      customDrillingOptions.right_bottom = $("#right_bottom").val();
      customDrillingOptions.right_reverse = $("#right_reverse").val();
      customDrillingOptions.right_forward = $("#right_forward").val();
      customDrillingOptions.right_hole = $("#right_hole").val();
      customDrillingOptions.right_true = $("#right_true").val();
      customDrillingOptions.right_cut = $("#right_cut").val();
      customDrillingOptions.thumb_reverse = $("#thumb_reverse").val();
      customDrillingOptions.thumb_forward = $("#thumb_forward").val();
      customDrillingOptions.thumb_hole = $("#thumb_hole").val();
      $("#hdnCustomDrillingVars").val(JSON.stringify(customDrillingOptions));
    } else {
      e.preventDefault();
    }
  });
}

export default CustomDrilling;
