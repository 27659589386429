import algoliasearch from "algoliasearch/lite";
import $ from "jquery";
import autocomplete from "autocomplete.js";
import { getTrendingProductString } from "./trendingproduct";
import { AlgoliaResponse, AlgoliaSearchHit } from "../local-types/algoliaitem";

(() => {
  const algoliasearchClient = algoliasearch(
    "Z8J5R4NOF8",
    "99c826d84a378cb08de36da259621c31"
  );
  const algoliasearchIndex = algoliasearchClient.initIndex("bowling_com");

  const siteSearchEls = document.querySelectorAll("[data-search-autocomplete]");
  siteSearchEls.forEach((el: HTMLElement) => {
    autocomplete(
      `#${el.id}`,
      {
        minLength: 3,
        appendTo: el.dataset.searchAutocompleteResultsContainer,
        autoWidth: false,
        hint: false,
        debug: true,
      },
      [
        {
          source: autocomplete.sources.hits(algoliasearchIndex, {
            filters: "discontinued=0",
            hitsPerPage: 5,
            removeWordsIfNoResults: "allOptional",
          }),
          displayKey: "name",
          templates: {
            suggestion: (suggestion: AlgoliaSearchHit) => {
              const trendingProdString = getTrendingProductString(
                suggestion,
                "Search Autocomplete"
              );
              return `<div class="px-0 py-0">${trendingProdString}</div>`;
            },
            footer: (data: AlgoliaResponse) =>
              `<div class="px-3 pt-2 pb-3 text-center"><a class="btn btn-lg btn-primary d-block my-2" href="/search/results?s=${data.query}">Show All&nbsp;&nbsp;<i class="fa-solid fa-magnifying-glass fa-lg" data-fa-transform="flip-h"></i></a></div>`,
          },
        },
      ]
    ).on(
      "autocomplete:selected",
      (
        event: any,
        suggestion: AlgoliaSearchHit,
        dataset: any,
        context: any
      ) => {
        // Do nothing on click, as the browser will already do it
        if (context.selectionMethod === "click") {
          return;
        }
        // Change the page, for example, on other events
        window.location.assign(suggestion.url);
      }
    );
  });

  $("#frm_search").submit(() => {
    $("#frm_search").LoadingOverlay("show", {
      background: "rgba(248, 249, 250, 0.8)",
      imageColor: "rgba(52, 58, 64, 1)",
      zIndex: 5000000,
    });
  });
})();
