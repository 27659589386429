import $ from "jquery";
import "bootstrap";
import "./components/searchbox";
import "./components/searchpagebox";
import dayjs from "dayjs";
import axios from "axios";
import { confetti } from "dom-confetti";
import { openNav, closeNav } from "./components/navHandling";
import "gasparesganga-jquery-loading-overlay";
import { scrollTo } from "./components/scrollto";
import * as validation from "./components/validation";
import { overlayOn, overlayOff } from "./components/overlay";
import "ekko-lightbox";
import "lazysizes";
import "../assets/scss/main/main.scss";
import { CustomDrilling } from "./customdrilling";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { all } from "@awesome.me/kit-5dd71facaf/icons";
import gtag from "./components/window.gtag";

interface Flags {
  dt: boolean;
  da: boolean;
}

interface BC {
  flags: Flags;
}

interface MyWindow extends Window {
  Tawk_API: any;
  Tawk_LoadStart: any;
  $: JQueryStatic;
  jQuery: JQueryStatic;
  ValidateAllValid: Function;
  AddElementValidation: Function;
  RemoveElementValidation: Function;
  ValidateWebformsButton: Function;
  overlayOn: Function;
  CustomDrilling: Function;
  confetti: Function;
  bc: BC;
  googletag: any;
}

declare const window: MyWindow;

library.add(...all);
dom.watch();

document.addEventListener("click", (e) => {
  var clickTarget = e.target;
  if (clickTarget instanceof HTMLAnchorElement) {
    //remove any hash and querystring from the url for better tracking
    const url = window.location.href.split("#")[0].split("?")[0];
    const linkClick = clickTarget.dataset.gaLinkClickLabel;
    if(linkClick) {
      gtag("event", "internal_click", {
        "click_page": window.location.href,
        "click_url": url,
        "click_label": linkClick,
        "click_type": "link"
      });
      return;
    }
    const productListClick = clickTarget.dataset.gaProductListClickLabel;
    if(productListClick) {
      gtag("event", "internal_click", {
        "click_page": window.location.href,
        "click_url": url,
        "click_label": productListClick,
        "click_type": "product-list"
      });
      return;
    }
    const bannerClick = clickTarget.dataset.gaBannerClickLabel;
    if(bannerClick) {
      gtag("event", "internal_click", {
        "click_page": window.location.href,
        "click_url": url,
        "click_label": bannerClick,
        "click_type": "banner"
      });
      return;
    }
  }
});

$('[data-toggle="tooltip"]').tooltip();

$(".js-navlink").on("click", (e) => {
  if ($(e.currentTarget).data("navtype") !== "") {
    openNav($(e.currentTarget).data("navtype"));
  }
});

$(".js-closenav").on("click", (e) => {
  if ($(e.currentTarget).data("navtype") !== "") {
    closeNav($(e.currentTarget).data("navtype"));
  }
});

// handles disabling buttons if data-disable-on-click is set
$(() => {
  $(document).on("click", 'button[data-disable-on-click="true"]', (e) => {
    $(e.currentTarget).prop("disabled", true);
    $(e.currentTarget).LoadingOverlay("show");
  });
});

// smooth scroll any anchor links that go to an id
$(document).on("click", 'a[href*="#"]', (e) => {
  if (
    $(e.currentTarget).attr("href").length > 1 &&
    typeof $(e.currentTarget).data("toggle") === "undefined"
  ) {
    e.preventDefault();
    scrollTo($(e.currentTarget).attr("href"));
  }
});

document.addEventListener(
  "change",
  (e) => {
    if (e.target instanceof HTMLInputElement || e.target instanceof HTMLSelectElement || e.target instanceof HTMLTextAreaElement) {
      const matchingElements = document.querySelectorAll(
        `.js-validation[data-validation-match="${e.target.id}"]`
      );
      if (e.target.matches(".js-validation")) {
        validation.validateIsValidElement(e.target);
      } else if (matchingElements !== null) {
        matchingElements.forEach((value: HTMLInputElement) => {
          validation.validateIsValidElement(value);
        });
      } else if (
        e.target.nodeName === "INPUT" &&
        e.target.getAttribute("type") === "radio"
      ) {
        const matchingRadios = document.querySelectorAll(
          `input.js-validation[name="${e.target.name}"][type="radio"]`
        );
        if (matchingRadios !== null) {
          matchingRadios.forEach((value: HTMLInputElement) => {
            validation.validateIsValidElement(value);
          });
        }
      }
    }
  },
  false
);

$(() => {
  $("div.altimgbox a").click((e) => {
    e.preventDefault();
    $(".altimgbox").removeClass("hilite");
    $(e.currentTarget).parent().addClass("hilite");
    const imgId = $(e.currentTarget).attr("data-id");
    $(".prod_img_container").hide();
    $(`#prodimage_${imgId}`).show();
  });
  $("div.altimgbox img.altvidlink").click((e) => {
    const vidId = $(e.currentTarget).attr("data-id");
    $(".prod_img_container").hide();
    $(`#vidimage_${vidId}`).show();
    $(".altimgbox").removeClass("hilite");
    $(e.currentTarget).parent().addClass("hilite");
    $(`#vidimage_${vidId} a.bigvidlink`).click();
  });
  $("div.altimgbox a").mouseover((e) => {
    $(".altimgbox").removeClass("hilite");
    $(e.currentTarget).parent().addClass("hilite");
    const imgId = $(e.currentTarget).attr("data-id");
    $(".prod_img_container").hide();
    $(`#prodimage_${imgId}`).show();
  });
  $("div.altimgbox img.altvidlink").mouseover((e) => {
    const vidId = $(e.currentTarget).attr("data-id");
    $(".prod_img_container").hide();
    $(`#vidimage_${vidId}`).show();
    $(".altimgbox").removeClass("hilite");
    $(e.currentTarget).parent().addClass("hilite");
  });
});

$(() => {
  // hide magnifying glass when clicked in checkout
  $("#lnk_showsearch").click(() => {
    $("#checkout_searchicon").hide();
  });
});

$(document).on("click", '[data-toggle="lightbox"]', (e) => {
  e.preventDefault();
  if ($(e.currentTarget).hasClass("lblink")) {
    if ($(e.currentTarget).attr("data-bg")) {
      const dataBg = $(e.currentTarget).attr("data-bg");
      $("body").addClass(`ekko-lightbox-${dataBg}`);
      $(e.currentTarget).ekkoLightbox({
        alwaysShowClose: true,
        onHidden: () => {
          $("body").removeClass(`ekko-lightbox-${dataBg}`);
        },
      });
    } else {
      $(e.currentTarget).ekkoLightbox({
        alwaysShowClose: true,
      });
    }
  } else {
    $(e.currentTarget).ekkoLightbox();
  }
});

// add alt images to all ads (and any images without alt tags)
$(window).on("load", () => {
  $("img:not([alt])").attr("alt", "ad image");
  $("textarea:not([title])").attr("title", "Write a Description");
});

interface BusinessHoursResponse {
  openTime: string;
  closeTime: string;
  currentTime: string;
}

axios
  .get<BusinessHoursResponse>("/api/businesshours")
  .then((businessHours) => {
    if (typeof businessHours !== "undefined") {
      const openTime = dayjs(businessHours.data.openTime);
      const closeTime = dayjs(businessHours.data.closeTime);
      const currentTime = dayjs(businessHours.data.currentTime);
      if (openTime <= currentTime && closeTime > currentTime) {
        document.body.classList.add("js-business-open");
      } else {
        document.body.classList.add("js-business-closed");
      }
    } else {
      document.body.classList.add("js-business-closed");
    }
  })
  .catch(() => {
    document.body.classList.add("js-business-closed");
  });

function activateLiveChatToggles() {
  const liveChatToggles = document.querySelectorAll(".js-liveChatToggle");
  for (let i = 0; i < liveChatToggles.length; i += 1) {
    liveChatToggles[i].addEventListener("click", () => {
      if (
        typeof window.Tawk_API !== "undefined" &&
        window.Tawk_API.getStatus() === "online"
      ) {
        window.Tawk_API.toggle();
      } else if (document.querySelector("#chatInfoClosed") !== null) {
        $("#chatInfoClosed").ekkoLightbox();
      }
    });
  }
}
activateLiveChatToggles();

// open promos and pricing modal from link click
document.addEventListener("DOMContentLoaded", () => {
  if (window.location.hash === "#empdet") {
    const promopricedet = document.querySelector("#lnk_promopricedet");
    if (promopricedet !== null && promopricedet instanceof HTMLAnchorElement)
      promopricedet.click();
  }
  if (window.location.hash === "#howtovip") {
    const howToUseVip = document.querySelector("#lnk_hotousevip");
    if (howToUseVip !== null && howToUseVip instanceof HTMLAnchorElement)
      howToUseVip.click();
  }
  if (window.location.hash === "#vipsignup") {
    const vipSignup = document.querySelector("#lnk_vipsignup");
    if (vipSignup !== null && vipSignup instanceof HTMLAnchorElement)
      vipSignup.click();
  }
});

// Hide the overlay when the page loads to prevent it persisting if the back button is hit.
window.addEventListener("pageshow", () => {
  overlayOff();
});

// handles shipping options form js which can be loaded on multiple pages
document.addEventListener("click", (e) => {
  if (e.target instanceof Element) {
    if (e.target.closest("button.js-shippingoptions-submit")) {
      const submitButton = e.target.closest("button.js-shippingoptions-submit");
      const container = submitButton.closest(
        ".shippingoptions"
      ) as HTMLFormElement;
      if (!validation.validateAllValid(container)) {
        e.preventDefault();
        return false;
      }
    }
  }
  return true;
});
document.addEventListener("click", async (e) => {
  if (
    e.target instanceof Element &&
    e.target.closest("#btnApplyEntryCouponPromo")
  ) {
    const btnApplyEntryCouponPromo = e.target.closest(
      "#btnApplyEntryCouponPromo"
    ) as HTMLButtonElement;
    const entryCoupon = document.getElementById("entryCoupon");
    const entryCouponStart = document.getElementById("entryCouponStart");
    const entryCouponApplied = document.getElementById("entryCouponApplied");
    btnApplyEntryCouponPromo.disabled = true;
    btnApplyEntryCouponPromo.innerHTML =
      '<i class="fa fa-spin fa-spinner"></i> Applying Coupon...';
    const url = new URL("/cart/addcoupon.aspx", document.location.href);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code: "welcome5" }),
    });
    if (response.ok) {
      entryCouponStart.style.display = "none";
      entryCouponApplied.style.display = "block";
    } else {
      entryCoupon.style.display = "none";
    }
  }
});

if (
  document.querySelector("#frm_footersignup") &&
  document.querySelector("#emailSignupInput")
) {
  // form submit handler for Listrak footer signup form
  $("#frm_footersignup").submit((e) => {
    // check that the checkbox is checked
    if (!$("#emailSignupInput").is(":checked")) {
      // prevent the default form submit if it is not checked
      e.preventDefault();
    }
  });
}

function showHideChatTrigger(showTrigger: boolean) {
  const chatTriggerContainer = document.querySelector("#chatTriggerContainer");
  if (showTrigger) {
    chatTriggerContainer.classList.remove("d-none");
  } else {
    chatTriggerContainer.classList.add("d-none");
  }
}

window.Tawk_API = window.Tawk_API || {};
window.Tawk_LoadStart = new Date();
window.Tawk_API.onLoad = () => {
  showHideChatTrigger(window.Tawk_API.getStatus() === "online");
};
window.Tawk_API.onStatusChange = (status: string) => {
  showHideChatTrigger(status === "online");
};
window.Tawk_API.onChatMaximized = () => {
  showHideChatTrigger(false);
};
window.Tawk_API.onChatMinimized = () => {
  showHideChatTrigger(true);
};
window.Tawk_API.onChatHidden = () => {
  showHideChatTrigger(true);
};

const loadDelayedScripts = () => {
  const firstScript = document.getElementsByTagName("script")[0];
  if (!window.bc.flags.dt) {
    const tawk = document.createElement("script");
    tawk.async = true;
    tawk.src = 'https://embed.tawk.to/55b2a64e5c92ab1f7018d840/default';
    tawk.charset = 'UTF-8';
    tawk.setAttribute('crossorigin', '*');
    firstScript.parentNode.insertBefore(tawk, firstScript);
  }
};

setTimeout(function () {
  loadDelayedScripts();
}, 5000);


// show overlay when any product is clicked.
$("a.prlnk").on("click", (e) => {
  $(e.currentTarget).find($("div.noloadoverlay")).removeClass("noloadoverlay");
});
$("a.promolink").on("click", (e) => {
  $(e.currentTarget).find($("div.noloadoverlay")).removeClass("noloadoverlay");
});
$(window).on("pageshow", () => {
  $("body").LoadingOverlay("hide");
  $("div.prod-img-box img").LoadingOverlay("hide");
  $("div.loadoverlay").addClass("noloadoverlay");
});
$(window).on("load", () => {
  $("body").LoadingOverlay("hide");
  $("div.prod-img-box img").LoadingOverlay("hide");
  $("div.loadoverlay").addClass("noloadoverlay");
});   

$('span#hamburger').click(function () {
  $('input[type="checkbox"]#menu').prop('checked', true);
});
$('div#hamburger').click(function () {
  $('input[type="checkbox"]#menu').prop('checked', true);
});
$('label.menu-toggle').click(function () {
  $(this).closest('li').find('[type=checkbox]').prop('checked', false);
});

window.googletag = window.googletag || { cmd: [] };

// Exposed to global to keep on-page stuff working
window.$ = $;
window.jQuery = $;
window.ValidateAllValid = validation.validateAllValid;
window.AddElementValidation = validation.addElementValidation;
window.RemoveElementValidation = validation.removeElementValidation;
window.ValidateWebformsButton = validation.validateWebformsButton;
window.overlayOn = overlayOn;
window.CustomDrilling = CustomDrilling;
window.confetti = confetti;
